//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import { formatPriceWithoutPrefix } from '@/utils/helpers/string-helper'
import { defaultPaginationAttributes, assignResponseToDataModelData, resetPaginationData } from '@/utils/helpers/pagination-helper'
export default {
  data () {
    return {
      tabs: [
        {
          id: 1,
          label: 'Semua',
          value: 'all'
        },
        {
          id: 2,
          label: 'Didapatkan',
          value: 'in'
        },
        {
          id: 3,
          label: 'Digunakan',
          value: 'out'
        }
      ],
      pointType: 'all',
      pointHistory: {
        ...defaultPaginationAttributes()
      },
      infiniteId: +new Date()
    }
  },
  computed: {
    myPoint () {
      return this.$auth.$state.user.paninti_point
    }
  },
  watch: {
    '$route.query': {
      async handler () {
        resetPaginationData(this.pointHistory)
        await this.fetchHistoryPoint()
        this.infiniteId++
      },
      deep: true
    }
  },
  mounted () {
    this.fetchHistoryPoint()
  },
  methods: {
    ...mapActions('User', ['getHistoryPoint']),
    formatPriceWithoutPrefix,
    clickFilterPointHistories (tab) {
      this.pointType = tab.value
      this.$router.push({
        query: {
          ...this.$route.query,
          type: tab.value
        }
      })
    },
    async fetchHistoryPoint () {
      try {
        const resp = await this.getHistoryPoint({
          params: {
            paginated: true,
            page: this.pointHistory.page + 1 || 1,
            per_page: 10,
            type: this.$route.query.type === 'all' || !this.$route.query.type ? undefined : this.$route.query.type
          }
        })

        assignResponseToDataModelData(this.pointHistory, resp.data.data.balance_histories, false)
        if (this.$refs.InfiniteLoading) {
          if (this.pointHistory.page < this.pointHistory.totalPage) {
            this.$refs.InfiniteLoading.stateChanger.loaded()
          } else {
            this.$refs.InfiniteLoading.stateChanger.complete()
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleInfiniteLoading () {
      this.fetchHistoryPoint()
    }
  }
}
