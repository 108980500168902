//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations } from 'vuex'
import VClamp from 'vue-clamp'

import panstoreMixin from '@/mixins/index'

import { cardProductPurposes } from '@/utils/helpers/product-helper'
import { storeTypes } from '@/utils/helpers/store-helper'
import { toastTypes } from '@/utils/helpers/toast-helper'
import { defaultSwal2Options } from '@/utils/helpers/swal2-helper'

export default {
  components: {
    VClamp
  },

  mixins: [panstoreMixin],

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    productSale: {
      type: Boolean,
      default: false
    },
    purpose: {
      type: String,
      default: 'general' // 'general' | 'affiliate' | 'flashsale' | 'add-cart'
    },
    showAddCartButton: {
      type: Boolean,
      default: false
    },
    // for flashsale purposes
    progress: {
      type: Number,
      default () {
        return 0
      }
    },
    showWishlist: {
      type: Boolean,
      default: true
    },
    // for affiliate purposes
    affiliateProp: {
      type: Object,
      default: () => ({})
    },
    showShare: {
      type: Boolean,
      default: false
    },
    showRating: {
      type: Boolean,
      default: false
    },
    showEstimatedAffiliate: {
      type: Boolean,
      default: false
    },
    showAffiliateAdd: {
      type: Boolean,
      default: false
    },
    checkedAffiliate: {
      type: Boolean,
      default: false
    },

    modalShareId: {
      type: String,
      default: 'modalShareProduct'
    }
  },

  data () {
    return {
      value: 4.5,
      colors: ['#FFCF44', '#FFCF44', '#FFCF44'],
      iconReview: '/img/icon/card-product/icon-review.svg',
      iconWishlist: '/img/icon/card-product/icon-wishlist.svg',
      iconRegistry: '/img/icon/card-product/icon-registry.svg',
      iconBuy: '/img/icon/card-product/icon-buy.svg',
      is_wishlisted: this.item.is_wishlisted,
      urlOnlyEvent: ['/clearance-sale', '/flashsale', '/sale/']
    }
  },
  computed: {
    storeTypes () {
      return storeTypes
    },
    cardClass () {
      let classes = this.purpose

      if (this.purpose === 'affiliate' && this.showEstimatedAffiliate) {
        classes += ' show-estimated'
      }

      if (this.purpose === 'affiliate' && this.showAffiliateAdd) {
        classes += ' show-add'
      }

      if (this.showAddCartButton) {
        classes += ' add-cart show-add'
      }
      return classes
    },
    remainingStatus () {
      if (this.progress === 100) {
        return 'Habis terjual';
      } else if (this.progress > 85) {
        return 'Hampir habis';
      } else if (this.progress > 65) {
        return 'Terjual cepat';
      } else if (this.progress > 45) {
        return 'Banyak dibeli';
      } else {
        return 'Masih tersedia';
      }
    },
    backgroundBarStatus () {
      if (this.progress > 85) {
        return 'bgBar-second';
      } else {
        return '';
      }
    },
    colorBarStatusText () {
      if (this.progress > 85) {
        return 'textBar-second';
      } else {
        return '';
      }
    },
    showDisc () {
      return this.item.price !== this.item.discounted_price
    },

    onFlashsale () {
      if (this.item.details[0].event_sale_session) {
        return this.getFlashTime(this.item.details[0].event_sale_session.date_start)
      } else {
        return false
      }
    },
    eventExist () {
      if (this.purpose === 'affiliate') {
        return false
      }
      let eventExist = false
      const eventRes = []

      Object.keys(this.item.event_ongoing).forEach((key, index) => {
        eventRes.push(this.item.event_ongoing[key].length !== 0)
      })

      eventExist = eventRes.includes(true)

      return eventExist
    },
    getImageOriginalCard () {
      let images = []

      const checkUrl = this.urlOnlyEvent.some((url) => {
        return this.$route.path.includes(url)
      })

      if (this.eventExist && this.item.type === 'combination' && checkUrl) {
        const mainDetail = this.item.details.filter((detail) => {
          return detail.main === true
        })

        images = mainDetail && mainDetail.length ? mainDetail[0].files : this.item.details[0].files
      } else {
        images = this.item.files
      }

      return this.checkArray(images) ? images : []
    },
    totalSoldText () {
      let soldText = this.item.total_sold > 0 ? `Terjual ${this.item.total_sold}` : 'Belum Terjual'

      if (this.item.is_preorder) {
        soldText = this.item.total_preorder > 0 ? `${this.item.total_preorder} Dipesan` : 'Belum Dipesan'
      }

      return soldText
    },
    isRangePrice () {
      return this.item.discounted_price.includes('-')
    }
  },

  methods: {
    ...mapMutations('Product', ['setShareUrl']),
    ...mapActions('User', ['toggleWishlist']),
    ...mapActions('Product', ['getProductWishlist', 'deleteAffiliateProduct']),

    handleSetShareData () {
      this.$emit('btn-share-clicked', {
        name: this.item.name,
        share_link: this.item.share_link,
        share_text: this.item.share_text,
        price: this.item.price || '0',
        discounted_price: this.item.discounted_price || '0',
        files: this.item.files,
        is_affiliated: this.purpose === cardProductPurposes.affiliate,
        store: this.item.store ? {
          name: this.item.store.name
        } : {
          name: ''
        }
      })
    },

    getFlashTime (start) {
      const startTime = new Date(this.formatYMD(start))
      if (startTime.getTime() < new Date().getTime()) {
        return true
      } else {
        return false
      }
    },

    async handleToggleWishlist () {
      if (this.$auth.loggedIn) {
        try {
          const resp = await this.toggleWishlist({
            data: {
              product_id: [this.item.id]
            }
          })

          if (resp.status === 200) {
            await this.getProductWishlist({
              data: { session_id: this.$cookies.get('session_id') }
            })

            this.iconWishlist =
              this.iconWishlist ===
              '/img/icon/card-product/icon-wishlist-filled.svg'
                ? '/img/icon/card-product/icon-wishlist.svg'
                : '/img/icon/card-product/icon-wishlist-filled.svg'

            this.is_wishlisted = !this.is_wishlisted

            this.$message({
              message: `Produk berhasil ${
                !this.is_wishlisted ? 'dihapus dari ' : 'ditambahkan ke'
              } Wishlist.`,
              type: 'success',
              showClose: true
            })
          }
        } catch (error) {
          console.log(error)
          this.$message({
            message: 'Oops, Terjadi kesalahan.',
            type: 'error',
            showClose: true
          })
        } finally {
          this.$emit('wishlist-success')
        }
      }
    },

    getSortedFiles (files) {
      let dataFiles = JSON.parse(JSON.stringify(files))

      dataFiles = dataFiles.filter((file) => {
        return file.variant === 'thumbnail'
      })

      dataFiles = dataFiles.sort((a, b) => {
        return a.sequence - b.sequence
      })

      return dataFiles.length ? dataFiles : []
    },

    handleDeleteAffiliateProduct () {
      this.$Swal
        .fire({
          ...defaultSwal2Options(),
          title: 'Hapus produk afiliasi',
          text: 'Anda akan menghapus produk dari afiliasi?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Ya, Hapus',
          cancelButtonText: 'Batal'
        }).then(async (result) => {
          if (!result.isConfirmed) { return }

          try {
            await this.deleteAffiliateProduct({
              data: {
                id: [this.affiliateProp.id]
              }
            })
            this.$emit('delete-affiliate-item', {
              affiliateProductId: this.affiliateProp.id,
              productId: this.item.id,
              productName: this.item.name
            })
          } catch (error) {
            console.log(error)

            this.$message({
              message: 'Gagal menghapus produk afiliasi',
              showClose: true,
              type: toastTypes.error.value
            })
          }
        })
    },

    addToAffiliate () {
      this.$emit('add-to-affiliate', {
        id: this.item.id,
        productName: this.item.name,
        checked: !this.checkedAffiliate
      })
    },
    addToCart () {
      this.$emit('add-to-cart', this.item)
    }
  }
}
