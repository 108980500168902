//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import {
  myFavoriteProductsSortOptions,
  emptyDescription,
  myFavoriteType
} from '@/utils/helpers/my-favorite-helper'
import { generateRandomString } from '@/utils/helpers/string-helper'
import { filterNonNullValues } from '@/utils/helpers/object-helper'
import { toastTypes } from '@/utils/helpers/toast-helper'
import { mapActions, mapMutations } from 'vuex'
export default {
  props: {
    type: {
      type: String,
      default: 'wishlist'
    }
  },
  data () {
    return {
      listMyFavoriteProductsFilter: {
        search: '',
        sort: myFavoriteProductsSortOptions.latest.value
      },
      products: {
        isLoading: true,
        page: 1,
        perPage: 8,
        totalPage: 0,
        total: 0,
        data: []
      }
    }
  },
  computed: {
    myFavoriteProductsSortOptions () {
      return myFavoriteProductsSortOptions
    },
    emptyDescription () {
      return emptyDescription
    },
    myFavoriteType () {
      return myFavoriteType
    }
  },
  watch: {
    $route () {
      this.resetDataState()
      this.getTabData()
    }
  },
  mounted () {
    this.getTabData()
  },
  methods: {
    ...mapActions('Cart', [
      'addCart',
      'getCarts',
      'getCartsNavbar',
      'getEmptyCarts'
    ]),
    ...mapActions('Product', [
      'getProductsWishlist',
      'getProductsLastSeen',
      'deleteProductsLastSeen'
    ]),
    ...mapMutations('Cart', ['setSelectedProduct', 'setSelectedVariant']),
    generateRandomString,
    resetDataState () {
      this.products = {
        isLoading: true,
        page: 1,
        perPage: 8,
        totalPage: 0,
        total: 0,
        data: []
      }
      this.listMyFavoriteProductsFilter = {
        search: this.$route.query.q || '',
        sort:
          this.$route.query.sort || myFavoriteProductsSortOptions.latest.value
      }
    },
    handleInput (search) {
      this.products.isLoading = true
      this.products.data = []
      this.handleChangeInputSearch(search)
    },
    handleChangeInputSearch: _.debounce(function (searchValue) {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          q: searchValue || null
        })
      })
    }, 700),

    handleChangeSelectSort (sortValue) {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          sort: sortValue
        })
      })
    },
    handleInfiniteLoading () {
      this.getTabData()
    },
    handleWishlistSuccess () {
      this.products.page = 1
      this.getTabData()
    },
    getTabData: _.debounce(async function () {
      this.products.isLoading = true
      try {
        let resp
        const requestPayload = {
          params: {
            page: this.products.page,
            per_page: this.products.perPage,
            paginated: true,
            order_by: this.listMyFavoriteProductsFilter.sort || undefined,
            search: this.listMyFavoriteProductsFilter.search || undefined
          }
        }

        switch (this.type) {
          case 'wishlist':
            resp = await this.getProductsWishlist(requestPayload)
            break
          case 'lastseen':
            resp = await this.getProductsLastSeen(requestPayload)
            break
        }

        if (resp) {
          this.products.total = resp.data.data.products.total
          this.products.totalPage = resp.data.data.products.last_page
          if (this.products.page === 1) {
            this.products.data = resp.data.data.products.data
          } else {
            this.products.data = [
              ...this.products.data,
              ...resp.data.data.products.data
            ]
          }
          this.products.page++

          if (this.products.page > this.products.totalPage) {
            if (
              Array.isArray(this.$refs.InfiniteLoading) &&
              this.$refs.InfiniteLoading.length > 0
            ) {
              this.$refs.InfiniteLoading[0].stateChanger.complete()
            } else if (this.$refs.InfiniteLoading) {
              this.$refs.InfiniteLoading.stateChanger.complete()
            }
          } else if (
            Array.isArray(this.$refs.InfiniteLoading) &&
            this.$refs.InfiniteLoading.length > 0
          ) {
            this.$refs.InfiniteLoading[0].stateChanger.loaded()
          } else if (this.$refs.InfiniteLoading) {
            this.$refs.InfiniteLoading.stateChanger.loaded()
          }
        }
      } catch (error) {
        this.$message.error(`Gagal mendapatkan data ${this.type} produk`)
      }

      this.products.isLoading = false
    }, 700),
    async handleAddCart (item) {
      await this.setSelectedProduct(item)

      if (item.type === 'simple') {
        const payload = Object.assign({
          data: {
            product_detail_id: item.details[0].id,
            quantity: 1,
            session_id: this.$cookies.get('session_id'),
            store_id: item.store.id,
            is_fulfilled: item.is_fulfilled || false
          }
        })

        try {
          const resp = await this.addCart(payload)

          if (resp.data.status_code === 201) {
            await this.setSelectedVariant(null)
            await this.handleGetCart()
            this.$refs.showAlert.click()
          } else {
            this.$message({
              showClose: true,
              message: resp.data.message,
              type: toastTypes.error.value
            })
          }
        } catch (error) {
          console.log(error)
          const errorMessage = error.response
            ? error.response.data.message
            : error
          this.$message({
            showClose: true,
            message: errorMessage,
            type: toastTypes.error.value
          })
        }
      } else {
        this.$refs.showModal.click()
      }
    },
    async handleGetCart () {
      let payload = {
        session_id: this.$cookies.get('session_id'),
        order_by: { created_at: 'desc' }
      }

      if (this.$route.name === 'cart') {
        payload = await Object.assign({
          ...payload,
          conditions: {
            stock: 'available_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        const unAvail = await Object.assign({
          ...payload,
          conditions: {
            stock: 'unavailable_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })

        try {
          await this.getEmptyCarts({ data: unAvail })
        } catch (error) {
          console.log(error)
        }
      }

      try {
        await this.getCartsNavbar({
          params: {
            session_id: payload.session_id
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    handleDeleteHistory () {
      this.$Swal
        .fire({
          title: 'Apakah Anda yakin?',
          text: 'Anda akan menghapus riwayat terakhir diliat',
          icon: 'question',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: '#1659AB',
          confirmButtonText: 'Ya, Hapus',
          cancelButtonText: 'Batal',
          reverseButtons: true
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const resp = await this.deleteProductsLastSeen({
                data: {
                  all_products: true
                }
              })
              if (resp.data.status_code === 200) {
                this.products.page = 1
                this.$message.success(
                  'Riwayat terakhir dilihat berhasil dihapus'
                )
                this.getTabData()
              }
            } catch (error) {
              console.log(error)
            }
          }
        })
    }
  }
}
