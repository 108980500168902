import { render, staticRenderFns } from "./MyFavoriteProductSection.vue?vue&type=template&id=69541020&scoped=true&"
import script from "./MyFavoriteProductSection.vue?vue&type=script&lang=js&"
export * from "./MyFavoriteProductSection.vue?vue&type=script&lang=js&"
import style0 from "./MyFavoriteProductSection.vue?vue&type=style&index=0&id=69541020&lang=scss&scoped=true&"
import style1 from "./MyFavoriteProductSection.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69541020",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconFilter: require('/usr/src/app/components/icons/IconFilter.vue').default,CardProduct: require('/usr/src/app/components/home/base/CardProduct.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,ProductCardSkeletonLoading: require('/usr/src/app/components/base/ProductCardSkeletonLoading.vue').default,MyFavoriteEmptyProduct: require('/usr/src/app/components/account/my-favorite/MyFavoriteEmptyProduct.vue').default,CardBase: require('/usr/src/app/components/base/CardBase.vue').default})
