//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { handleFormatRupiah } from '@/utils/helpers/string-helper'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'
import { formatDateWithTimezone } from '@/utils/helpers/date-time-helper'
export default {
  props: {
    historyItem: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    pointData () {
      const pointHistoryData = this.historyItem.balance_histories

      if (isArrayNotNullOrEmpty(pointHistoryData)) {
        return pointHistoryData[0]
      }

      return null
    },
    classes () {
      const arrClass = []

      if (this.historyItem.balance_type) {
        arrClass.push(this.historyItem.balance_type)
      }

      return arrClass
    }
  },
  methods: {
    handleFormatRupiah,
    formatDateWithTimezone
  }
}
