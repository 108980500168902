//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'

export default {
  layout: 'account',
  middleware: ['user'],

  data () {
    return {
      breadCrumb: [
        {
          path: '/account/home'
        },
        {
          name: 'Wishlist'
        }
      ]
    }
  },

  computed: {
    ...mapState('Base', ['dataBreadCrumbs'])
  },

  mounted () {
    this.setBreadCrumbs(this.breadCrumb)
  },

  methods: {
    ...mapMutations('Base', ['setBreadCrumbs'])
  }
}
