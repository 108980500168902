//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
import productCardSkeletonLoadingMixin from '@/mixins/product-card-skeleton-loading-mixin'

export default {
  mixins: [panstoreMixin, productCardSkeletonLoadingMixin],

  data () {
    return {
      productCardCount: 5,
      perPage: 10,
      onload: false,
      tab: 'rekomendasi',

      datas: {
        rekomendasi: [],
        lastseen: [],
        brand: [],
        store: [],
        lastsearch: []
      },
      totalPage: {
        rekomendasi: 0,
        lastseen: 0,
        brand: 0,
        store: 0,
        lastsearch: 0
      },
      page: {
        rekomendasi: 1,
        lastseen: 1,
        brand: 1,
        store: 1,
        lastsearch: 1
      },

      tabsPane: [
        {
          name: 'Rekomendasi',
          value: 'rekomendasi'
        },
        {
          name: 'Dari Brand Partner',
          value: 'brand'
        },
        {
          name: 'Dari Store Partner',
          value: 'store'
        },
        {
          name: 'Produk Terakhir Dilihat',
          value: 'lastseen'
        }
        // {
        //   name: 'Produk Terakhir Dicari',
        //   value: 'lastsearch'
        // }
      ]
    }
  },

  computed: {
    ...mapState('User/address', ['location'])
  },

  watch: {
    location () {
      this.tab = 'rekomendasi'

      this.page.rekomendasi = 1
      this.page.brand = 1
      this.page.store = 1

      this.totalPage.rekomendasi = 0
      this.totalPage.brand = 0
      this.totalPage.store = 0

      this.datas.rekomendasi = []
      this.datas.store = []
      this.datas.brand = []

      this.handleGetProducts()
    }
  },
  async mounted () {
    await this.handleGetProducts()
  },
  methods: {
    ...mapActions('Product', ['getProductSection']),

    handleInfiniteLoading () {
      this.handleGetProducts()
    },

    handleClick (tab) {
      this.tab = tab.name
      if (this.page[this.tab] === 1) {
        this.handleGetProducts()
      }
    },

    async handleGetProducts () {
      this.onload = true

      if (
        this.page[this.tab] === 1 ||
        this.page[this.tab] <= this.totalPage[this.tab]
      ) {
        try {
          const payload = {
            data: {
              per_page: this.perPage,
              page: this.page[this.tab],
              session_id: this.$cookies.get('session_id')
            }
          }
          const cityId = localStorage.getItem('location')
            ? JSON.parse(localStorage.getItem('location')).city_id
            : 155

          let tempTab = 'recommendation'
          if (this.tab === 'lastseen') {
            tempTab = 'last-seen'
          } else if (this.tab === 'lastsearch') {
            tempTab = 'last-search'
          } else if (this.tab === 'brand') {
            tempTab = 'brand-partner-recommendation'
          } else if (this.tab === 'store') {
            tempTab = 'store-partner-recommendation'
          }

          if (['brand-partner-recommendation', 'store-partner-recommendation', 'recommendation'].includes(tempTab)) {
            payload.data = Object.assign({
              ...payload.data,
              city_id: cityId
            })
          }

          const resp = await this.getProductSection({
            section: tempTab,
            payload
          })

          this.totalPage[this.tab] = resp.data.data.products.last_page
          this.datas[this.tab] = this.datas[this.tab].concat(
            resp.data.data.products.data
          )
          this.page[this.tab]++

          if (this.page[this.tab] > 4 || this.page[this.tab] > this.totalPage[this.tab]) {
            if (
              Array.isArray(this.$refs['InfiniteLoading-' + this.tab]) && this.$refs['InfiniteLoading-' + this.tab].length > 0
            ) {
              this.$refs['InfiniteLoading-' + this.tab][0].stateChanger.complete()
            } else if (this.$refs['InfiniteLoading-' + this.tab]) {
              this.$refs['InfiniteLoading-' + this.tab].stateChanger.complete()
            }
          } else if (
            Array.isArray(this.$refs['InfiniteLoading-' + this.tab]) &&
            this.$refs['InfiniteLoading-' + this.tab].length > 0
          ) {
            this.$refs['InfiniteLoading-' + this.tab][0].stateChanger.loaded()
          } else if (this.$refs['InfiniteLoading-' + this.tab]) {
            this.$refs['InfiniteLoading-' + this.tab].stateChanger.loaded()
          }
        } catch (error) {
          let tabName = this.tabsPane.filter(tab => tab.value === this.tab)

          if (tabName.length > 0) {
            tabName = String(tabName[0].name)
          } else {
            tabName = ''
          }

          this.$message.error(`Gagal mendapatkan data ${tabName.toLowerCase()} produk`)
        }
      }

      this.onload = false
    }
  }
}
